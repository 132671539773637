// TODO FIXES

import React from 'react';
import Select, { components, Props } from 'react-select';
import { Fonts } from '../../helpers/constants';
import classnames from 'classnames';
import globalStyles from "../styles.module.css";
import CloseIcon from '../../icons/cancel.svg';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';

interface IValue {
  value: string | number | undefined;
  label: string | JSX.Element;
  component?: JSX.Element;
};

interface IProps extends Omit<Partial<Props>, 'value'> {
  suggestions: IValue[];
  onChange: any;
  value?: IValue | null | IValue[];
  inputLabel?: string;
  inputId?: string;
  defaultValue?: IValue | null | IValue[];
  hasCustomComponent?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  customSelectStyles?: any;
  className?: string;
  fullWidth?: boolean;
  children?: any;
  values?: IValue[];
  renderMenuOutside?: boolean;
  liveRegionId?: string;
  handleSelectMenu?: Function;
  showSearch?: boolean;
  menuPosition?: any;
}

const reactSelectControlWidth = Math.min(window.innerWidth * 0.6, 300);

export default function ReactSelect({
  suggestions,
  value,
  onChange,
  inputLabel = '',
  inputId,
  defaultValue = null,
  hasCustomComponent = false,
  isSearchable = true,
  placeholder = '',
  customSelectStyles = {},
  className = '',
  fullWidth = false,
  renderMenuOutside = false,
  menuPortalTarget = renderMenuOutside ? document.body : undefined,
  menuPlacement = renderMenuOutside ? 'auto' : undefined,
  menuPosition,
  components = {},
  liveRegionId,
  handleSelectMenu,
  ...rest
}: IProps): JSX.Element {
  const selectStyles: any = {
    input: (base) => ({
      ...base,
      height: 25,
      '& input': {
        font: 'inherit',
        marginBottom: -5,
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
      color: state.isSelected ? '#006ACA' : 'inherit',
      padding: '0px 20px',
      height: 'auto',
      lineHeight: 2.5,
      cursor: 'pointer',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      cursor: 'pointer',
      height: 35,
    }),
    control: (base, state) => ({
      ...base,
      color: state.isFocused ? '#777' : 'black',
      padding: 0, 
      display: 'flex',
      marginRight: 10,
      backgroundColor: 'transparent',
      width: fullWidth ? '100%' : reactSelectControlWidth,
      cursor: 'pointer',
      borderColor: state.isFocused ? '#03a9f4' : 'rgba(0,0,0,0.2)',
      '&:hover': {
        borderColor: '#03a9f4',
      },
      '&:focus': {
        outline: "2px solid var(--al-blue) !important",
        outlineOffset: "2px",
      }
    }),
    singleValue: (base) => ({
      ...base,
      color: 'inherit',
    }),
    menu: (base) => ({
      ...base,
      top: 'unset',
      zIndex: 3,
      minWidth: reactSelectControlWidth,
      width: renderMenuOutside ? base.width : fullWidth ? '100%' : 'auto',
      marginTop: 1,
      fontFamily: Fonts.MEDIUM,
      fontSize: 16,
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999
    }),
    menuList: (base) => {
      const styles = {
        ...base,
        fontFamily: Fonts.MEDIUM,
        fontSize: 16,
        "& .focused": {
          outline: "2px solid var(--al-blue)",
          outlineOffset: "-2px",
        },
      };

      if (menuPosition !== 'fixed') {
        styles.maxHeight = '40vh';
      }

      return styles;
    },
    container: () => ({
      fontSize: 16,
      fontWeight: 400,
    }),
    noOptionsMessage: () => ({
      padding: '0 10px',
    }),
    multiValue: () => ({
      display: 'flex',
      minWidth: 0,
      backgroundColor: 'rgb(230, 230, 230)',
      borderRadius: 2,
      margin: 2,
      boxSizing: 'border-box',
      '& .multi-value-remove': {
        alignItems: 'center',
        display: 'flex',
        borderRadius: 2,
        boxSizing: 'border-box',
        padding: 2,
        '&:hover': {
          backgroundColor: 'rgb(255, 189, 173)',
          color: 'rgb(222, 53, 11)'
        }
      }
    })
  };
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [ariaFocusMessage, setAriaFocusMessage] = React.useState('');
  const [blurState, setBlurState] = React.useState(false);
  const optionLength = suggestions.length;

  const handleSelectChange = (selectedItems, actionMeta) => {
    onChange(selectedItems || [], actionMeta);
    setSelectedOption(Array.isArray(selectedItems) ? selectedItems?.map(x => x.label) : selectedItems.label);
  };

  const onFocus = ({ focused, isDisabled, options }) => {
    const msg = `Option ${focused.label} is focussed. ${options.indexOf(focused) + 1} of ${optionLength} results available. ${isDisabled ? ', disabled' : ''}`;
    setAriaFocusMessage(msg);
    setBlurState(true);
    return "";
  };

  React.useEffect(() => {
    setAriaFocusMessage(`${selectedOption === null ? suggestions[0]?.label : selectedOption} selected`);
    handleSelectMenu?.(isMenuOpen);
  }, [isMenuOpen]);

  React.useEffect(() => {
    const el = document.querySelector(".react-select > div");
    const el2 = el?.querySelector("div");
    const elm = document.querySelector(".react-select #" + inputId);
    if (liveRegionId) {
      el2?.setAttribute('id', 'single-value-control');
      el2?.setAttribute('tabindex', '-1');
      elm?.setAttribute('aria-label', ',');
    }
  }, []);

  return (
    <>
      <div id={liveRegionId ? liveRegionId : 'my-custom-live'} role='status' className={globalStyles.visuallyHidden}>{blurState && ariaFocusMessage}</div>
      <Select
        styles={{ ...selectStyles, ...customSelectStyles }}
        inputId={inputId}
        aria-label={inputLabel || placeholder}
        components={{ Option, Menu, ClearIndicator, MultiValueRemove, Control: controlOptionValue, ...components }}
        options={suggestions}
        value={value}
        className={`react-select ${className}`}
        onChange={handleSelectChange}
        onBlur={() => setBlurState(false)}
        defaultValue={defaultValue || suggestions[0]}
        isSearchable={isSearchable}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        // hasCustomComponent={hasCustomComponent}
        menuPosition={menuPosition}
        noOptionsMessage={({ inputValue }) =>
          `No results matched ${inputValue.trim().length > 0 ? 'for "' + inputValue + '"' : ''}`
        }
        name="aria-live-option"
        onMenuOpen={() => {
          setIsMenuOpen(true)
        }}
        onMenuClose={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
        ariaLiveMessages={{
          onFocus,
        }}
        tabIndex={inputId ? -1 : 0}
        {...rest}
      />
    </>
  );
}

const controlOptionValue = (props: ControlProps<any, boolean>) => {
  const { children, innerProps, ...rest } = props;
  return (
    <components.Control
      {...rest}
      innerProps={
        props.selectProps.inputId
          ? {
              role: "combobox",
              "aria-expanded": props.menuIsOpen ? true : false,
              "aria-label": props.selectProps["aria-label"],
              tabIndex: 0,
              ...innerProps,
            }
          : { ...innerProps }
      }
    >
      {children}
    </components.Control>
  );
};

const Menu = (props) => {
  return (
    <components.Menu className="react-select-menu" {...props}>
      {props.children}
    </components.Menu>
  );
};

const Option = function (props: any) {
  return (
    <components.Option
      className={classnames('react-select-option', {
        selected: props.isSelected,
        focused: props.isFocused
      })}
      ref={props.innerRef}
      {...props}>
      {props.data.component ? props.data.component : props.data.label}
    </components.Option>
  )
}

const ClearIndicator = function (props: any) {
  return (
    <components.ClearIndicator {...props}>
      <div title="Remove All Filters">
        {/*// @ts-ignore */}
        <CloseIcon width={10} height={10} fill="currentColor" stroke="currentColor" strokeWidth={0} />
      </div>
    </components.ClearIndicator>
  );
}

const MultiValueRemove = function (props: any) {
  return (
    <components.MultiValueRemove {...props}>
      <div title="Remove" className="multi-value-remove">
        {/*// @ts-ignore */}
        <CloseIcon width={8} height={8} fill="currentColor" stroke="currentColor" strokeWidth={0} />
      </div>
    </components.MultiValueRemove>
  );
}

export function MultiSelectFilter({
  suggestions,
  children,
  onChange,
  values = [],
  menuPosition = 'right',
  inputLabel = '',
  placeholder,
  isDisabled = false,
  showSearch = true,
  className = '',
  renderMenuOutside = false,
  menuPortalTarget = renderMenuOutside ? document.body : undefined,
  menuPlacement = renderMenuOutside ? 'auto' : undefined,
  ...rest
}: IProps): JSX.Element {
  const selectStyles: any = {
    container: (base) => ({
      ...base,
      overflow: 'visible',
      fontSize: 16,
      fontWeight: 400,
      pointerEvents: isDisabled ? 'none' : 'all',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 3,
      width: 'auto',
      minWidth: 300,
      marginTop: showSearch ? 75 : 10,
      marginLeft: menuPosition === 'center' ? -150 : 0,
      borderRadius: '0 0 5px 5px',
      fontFamily: Fonts.MEDIUM,
      fontSize: 16,
    }),
    control: (base, state) => ({
      '& > div:first-of-type': {
        position: 'absolute',
        width: 300,
        marginLeft: menuPosition === 'center' ? -150 : 0,
        padding: 10,
        height: 65,
        top: 40,
        zIndex: 3,
        display: state.menuIsOpen && showSearch ? 'inline-block' : 'none',
        backgroundColor: state.menuIsOpen ? 'white' : 'transparent',
        borderRadius: '5px 5px 0 0',
        border: state.menuIsOpen ? '1px solid #ccc' : 'none',
        borderWidth: '1px 1px 0px 1px',

        '& > div': {
          width: '100%',
        }
      }
    }),
    input: (base) => ({
      ...base,
      padding: 5,
      height: 30,
      lineHeight: 1,
      border: '1px solid #ccc',
    }),
    option: (base, state) => {
      return {
        ...base,
        backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
        color: state.isSelected ? '#0077D1' : 'black',
        padding: '10px 20px',
        lineHeight: 1.2,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        '&::after': {
          display: state.isSelected ? 'block' : 'none',
          position: 'relative',
          content: '"\u274C"',
          color: 'red',
        }
      };
    },
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999
    }),
    multiValue: () => {
      return {
        display: 'none',
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 30,
      '& > div': {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
      }
    }),
  };

  const [value, setValue] = React.useState(values);

  React.useEffect(() => {
    if (values.length > 0) {
      setValue(values);
    }
  }, [values]);

  const selectRef: any = React.createRef();

  const handleChange = (selectedItems, actionMeta) => {
    setValue(selectedItems);
    onChange(selectedItems || [], actionMeta);
  };

  return (
    <div ref={selectRef} className={className}>
      <Select
        value={value}
        isMulti
        aria-label={inputLabel || placeholder}
        options={suggestions}
        styles={selectStyles}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        isClearable={false}
        placeholder={placeholder}
        onChange={handleChange}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          `No results ${inputValue.trim().length > 0 ? 'matched for "' + inputValue + '"' : ''}`
        }
        components={{
          DropdownIndicator: props => (
            <components.DropdownIndicator {...props}>
              {children}
            </components.DropdownIndicator>
          ),
        }}
        onMenuOpen={() => {
          setTimeout(() => {
            const inputElem = selectRef.current && selectRef.current.querySelector('input[type="text"]');

            if (inputElem) {
              inputElem.focus();
            }
          }, 100);
        }}
        {...rest}
      />
    </div>
  );
}

export function MultiReactSelect({
  suggestions,
  onChange,
  placeholder,
  defaultValue,
  ...rest
}: IProps): JSX.Element {
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
  return (
    <Select
      value={defaultValue?defaultValue:[]}
      isMulti
      aria-label={placeholder || rest.inputLabel}
      options={suggestions}
      styles={selectStyles} 
      closeMenuOnSelect={false}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  )
};

export function MultiReactSelectTooltip({
  suggestions,
  onChange,
  placeholder,
  defaultValue,
  ...rest
}: IProps): JSX.Element {
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
  const CustomMultiValue = (props) => {
    return (
        <div title={props.data.label} style={{
          overflow: "hidden",
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          borderRadius: '2px',
          color: 'hsl(0, 0%, 20%)',
          fontSize: '85%',
          padding: '3px',
          paddingLeft: '6px',
          boxSizing: 'border-box'
        }}> 
          {props.data.label}
        </div>
    );
  };
  return (
    <Select
      value={defaultValue?defaultValue:[]}
      isMulti
      components={{MultiValueLabel: CustomMultiValue}} 
      aria-label={placeholder || rest.inputLabel}
      options={suggestions}
      styles={selectStyles} 
      closeMenuOnSelect={false}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  )
};




export const ReactSelectComponents = components;
