import React from 'react';
import { SelectValidator } from '../formValidator';
import ReactSelect from '../inputs/ReactSelect';
import MenuItem from '@mui/material/MenuItem';
import { GradeLevels, GradeLevelsAsRange } from '../../helpers/constants';
import { makeStyles } from '@mui/styles';

interface IGradeLevelSelectorProps {
  isHigherEd?: boolean;
  showGradeLevelAsRange?: boolean;
  showHigherEdOption?: boolean;
  gradeLevelId?: string | null;
  onChange: Function;
  shouldValidate?: boolean;
  shouldHideLabelWhenSelected?: boolean;
  id?: string;
  showK1?: boolean;
  gradeLevelSuggestions?: any;
  validators?: any;
}

const useStyles = makeStyles({
  root: {
    minWidth: 170,
  }
});

export default function GradeLevelSelector({
  isHigherEd = false,
  showGradeLevelAsRange = false,
  showHigherEdOption = true,
  gradeLevelId = '',
  onChange,
  shouldValidate = true,
  shouldHideLabelWhenSelected = false,
  id = 'grade-levels',
  showK1 = false,
  gradeLevelSuggestions = null,
  validators = null,
}: IGradeLevelSelectorProps) {
  if (!isHigherEd) {
    const GradeLevelsRangeToShow = showK1
      ? [{ value: 6, label: 'Grade K - 1' }, ...GradeLevelsAsRange]
      : GradeLevelsAsRange;

    return showGradeLevelAsRange
      ? <Selector
        gradeLevels={GradeLevelsRangeToShow}
        onChange={onChange}
        value={gradeLevelId}
        showHigherEdOption={showHigherEdOption}
        shouldValidate={shouldValidate}
        shouldHideLabelWhenSelected={shouldHideLabelWhenSelected}
        id={id}
        validators={validators} />
      : <Selector
        gradeLevels={gradeLevelSuggestions || GradeLevels}
        onChange={onChange}
        value={gradeLevelId}
        showHigherEdOption={showHigherEdOption}
        shouldValidate={shouldValidate}
        shouldHideLabelWhenSelected={shouldHideLabelWhenSelected}
        id={id}
        validators={validators} />
  }
  else {
    return null;
  }
}

export function MultiGradeLevelSelector({
  onChange,
  placeholder,
  showK1,
  ...rest
}) {
  let gradeLevelOptions = GradeLevels;

  if (showK1 === false) {
    gradeLevelOptions = GradeLevels.filter(x => ![14, 1].includes(x.value));
  }

  return (
    <ReactSelect
      suggestions={gradeLevelOptions}
      placeholder={placeholder}
      inputLabel={placeholder}
      onChange={onChange}
      defaultValue={[]}
      isMulti
      fullWidth
      renderMenuOutside
      {...rest}
    />
  );
};

const Selector = ({ gradeLevels, onChange, value, showHigherEdOption, shouldValidate, id, shouldHideLabelWhenSelected, validators = null }) => {
  const gradeLevelsToUse = [...gradeLevels];
  const styles = useStyles({});

  if (!showHigherEdOption) {
    gradeLevelsToUse.pop();
  }

  return (
    <SelectValidator
      id={id}
      label="Grade level"
      onChange={onChange}
      fullWidth
      value={value || ''}
      className={`${styles.root} selectValidator`}
      shouldHideLabelWhenSelected={shouldHideLabelWhenSelected}
      required={shouldValidate}
      validators={validators}>
      {
        gradeLevelsToUse.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
      }
    </SelectValidator>
  );
}
