import React from 'react';
import TextField from '@mui/material/TextField';
import { TextValidator } from '../formValidator/FormValidator';
import CreatableSelect from 'react-select/creatable';
import Select, { components, Props, ControlProps } from 'react-select';
import { Fonts } from '../../helpers/constants';
import { makeStyles } from '@mui/styles';
import naturalCompare from 'natural-compare-lite';

const styles = makeStyles(theme => ({
  input: {
    padding: 5,
    height: 'auto',
    minWidth: 220,

    '& > div': {
      padding: 0,
    }
  },
}));

const selectStyles = {
  input: (base) => ({
    ...base,
    height: 30,
    '& input': {
      fontFamily: Fonts.SEMI_BOLD,
      minWidth: 220,
    },
  }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: Fonts.MEDIUM,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
    lineHeight: 2,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
    margin: 0,
    fontFamily: Fonts.MEDIUM,
    fontSize: 16,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '16px',
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '13px',
    padding: '0 5px !important',
    fontFamily: Fonts.MEDIUM,
    '& div': {
      backgroundColor: 'inherit',
    },
    '& div:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      background: 'rgba(0, 0, 0, 0.45)',
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      color: '#e0e0e0',
      fill: '#e0e0e0',
      cursor: 'pointer',
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    paddingLeft: '7px !important',
    paddingRight: '7px !important',
    fontSize: '100%',
  }),
};

interface ISuggestion {
  value: string | number;
  label: string;
};
interface IProps extends Props<ISuggestion> {
  suggestions: ISuggestion[];
  placeholder?: string;
  inputLabel?: string;
  defaultValues?: ISuggestion[];
  allowFreeTagging?: boolean;
  onChange: any;
  classes?: any;
  validators?: any;
  className?: string;
  maxLength?: number;
  renderMenuOutside?: boolean;
  multiChipInputId?: string;
  enableAutoFocus?: boolean;
};
class ReactSelectMultiple extends React.Component<IProps, any> {
  constructor(props: any, context: any) {
    super(props, context);

    this.state = {
      suggestions: props.suggestions.sort((a, b) => {
        return naturalCompare(a.label.toLowerCase().trim(), b.label.toLowerCase().trim());
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.suggestions.length === 0 && this.props.suggestions.length > 0) {
      const suggestions = this.props.suggestions.sort((a, b) => {
        return naturalCompare(a.label.toLowerCase().trim(), b.label.toLowerCase().trim());
      });

      this.setState({ suggestions });
    }
  }

  handleChange = (selectedItems) => {
    this.props.onChange(selectedItems || []);
  };

  render() {
    const { placeholder = '', allowFreeTagging, defaultValues = [], classes, inputLabel,
      validators, suggestions, onChange, maxLength, className = '', renderMenuOutside, multiChipInputId, ...rest } = this.props;

    const components = { Control, Menu };

    if (allowFreeTagging) {
      return (
        <CreatableSelect
          styles={selectStyles}
          inputId={multiChipInputId || "multiple-react-select"}
          // components={components}
          defaultValue={defaultValues as any}
          placeholder={placeholder}
          aria-label={inputLabel || placeholder}
          options={this.state.suggestions}
          onChange={this.handleChange}
          openMenuOnClick={false}
          isMulti
          autoFocus={!this.props.enableAutoFocus}
          menuPortalTarget={renderMenuOutside ? document.body : undefined}
          menuPlacement={renderMenuOutside ? 'auto' : undefined}
          onInputChange={inputValue => (
            (maxLength && inputValue.length > maxLength)
              ? inputValue.substr(0, maxLength)
              : inputValue
          )}
          noOptionsMessage={({ inputValue }) =>
            `No results ${inputValue.trim().length > 0 ? 'matched for "' + inputValue + '"' : ''}`
          }
          className={`react-select ${className}`}
          {...rest}
        />
      );
    }
    else {
      return (
        <Select
          styles={selectStyles}
          // components={components}
          defaultValue={defaultValues as any}
          placeholder={placeholder}
          aria-label={inputLabel || placeholder}
          options={this.state.suggestions}
          onChange={this.handleChange}
          isMulti
          autoFocus={!this.props.enableAutoFocus}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          noOptionsMessage={({ inputValue }) =>
            `No results ${inputValue.trim().length > 0 ? 'matched for "' + inputValue + '"' : ''}`
          }
          className={`react-select ${className}`}
          onInputChange={inputValue => (
            (maxLength && inputValue.length > maxLength)
              ? inputValue.substr(0, maxLength)
              : inputValue
          )}
          {...rest}
        />
      );
    }
  }
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Menu = (props) => {
  return (
    <components.Menu className="react-select-menu" {...props}>
      {props.children}
    </components.Menu>
  );
};

function Control({ children, ...props }: ControlProps<ISuggestion, false>) {
  const {
    innerRef,
    selectProps,
  } = props;
  const classes = styles();
  const { validators } = (selectProps as any);
  const innerProps = (props.innerProps as any);

  return (validators
    ? <TextValidator
      fullWidth
      name="react-select-multiple"
      id="react-select-multiple"
      validators={validators}
      label=""
      margin="dense"
      height={30}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
    : <TextField
      fullWidth
      name="react-select-multiple"
      id="react-select-multiple"
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
  );
}

export default ReactSelectMultiple as React.ComponentClass<IProps, any>;
