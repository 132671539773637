import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Fonts } from '../../helpers/constants';
import { Tab, Tabs } from '@mui/material';

interface TabProps {
  key: string;
  label: string;
}

interface IProps {
  tabs: TabProps[];
  onChange: Function;
  selectedTab: string;
}

// Styled TabButton
const StyledTabButton = styled(Tab, { 
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected: boolean }>(({ selected }) => ({
  fontFamily: Fonts.BOLD,
  padding: '6px 15px',
  textTransform: 'uppercase',
  letterSpacing: '1.7px',
  lineHeight: 1,
  minHeight: 'initial',
  backgroundColor: selected ? '#0077d1' : '#e4f3ff',
  border: selected ? 'solid 2px #0077d1' : 'solid 2px #296AA9',
  borderRight: 0,
  color: selected ? '#fff' : '#296AA9',
  cursor: 'pointer',
  '&:focus': {
    outline: '2px solid #ffffff',
    outlineOffset: '-4px',
  },
  '&:first-of-type': {
    borderRadius: '30px 0 0 30px',
  },
  '&:last-of-type': {
    borderRadius: '0 30px 30px 0',
    borderRight: 'solid 2px #0077d1',
  },
}));

// TabSwitch Component
const TabSwitch = (props: IProps): JSX.Element => {
  const { tabs, selectedTab, onChange } = props;
  const [value, setValue] = useState(tabs.findIndex(data => data.key === selectedTab) || 0);

  return (
    <Tabs
      selectionFollowsFocus
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      value={value}
      TabIndicatorProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      {tabs.map((tab) => (
        <StyledTabButton
          disableRipple
          key={tab.key}
          onFocus={(e) => e.target.click()}
          onClick={() => onChange(tab.key)}
          selected={selectedTab === tab.key}
          label={tab.label}
          tabIndex={0}
        />
      ))}
    </Tabs>
  );
};

export default TabSwitch;
