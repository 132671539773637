import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import bowser from 'bowser';
import { renderToString } from 'react-dom/server';
import WarningIcon from '../../icons/warning.svg';

import { DateTimePicker as ResponsiveDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

const useStyles = makeStyles({
  dateTimePicker: {
    fontWeight: 600,
    maxWidth: 250,
    '& .MuiFormHelperText-root': {
      display: 'flex',
      margin: '0',
      '& span': {
        display: 'inline-flex',
        marginRight: '3px',
        '& svg': {
          stroke: 'var(--grade-colors-incomplete)',
          fill: 'var(--grade-colors-incomplete)',
          width: 25,
          height: 25,
          '& g': {
            fill: 'inherit'
          }
        }
      }
    }
  },
  withoutKeyboard: {
    '& input': {
      cursor: 'pointer',
    },
  },
});

export interface IPropsDateTimePicker {
  id: string;
  label?: string;
  value: Date | null | string;
  onChange,
  format?: string;
  inputVariant?: 'outlined';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  autoOk?: boolean;
  allowKeyboardControl?: boolean;
  ampm?: boolean;
  clearable?: boolean;
  disableToolbar?: boolean;
  className?: string;
  placeholder?: string;
  minDateMessage?: string;
  [key: string]: any;
};

export default function DateTimePicker({
  id,
  label = '',
  value,
  onChange,
  withKeyBoard = false, // Don't use with keyboard as am/pm typing does not work currently
  format = 'MM/DD/YYYY hh:mm a',
  inputVariant = 'outlined',
  disabled = false,
  disableFuture = false,
  disablePast = true,
  autoOk = !withKeyBoard,
  allowKeyboardControl = true,
  ampm = true,
  clearable = false,
  disableToolbar = false,
  className = '',
  placeholder = withKeyBoard ? 'mm/dd/yyyy hh:mm' : '',
  minDateMessage = 'Date should not be in the past',
  showTodayButton = true,
  ...rest
}: IPropsDateTimePicker) {
  const styles = useStyles();
  // Need local state to set value locally but do not update in parent state when value is invalid
  const [val, setVal] = useState(value);
  const [error, setError] = useState<any>(null);
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 320;
  const isMobileDevice = bowser.mobile || isMobile;

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // sync local state with parent state
    setVal(value);
  }, [value]);

  const classes = classnames([styles.dateTimePicker], className, {
    [styles.withoutKeyboard]: !withKeyBoard,
  });

  useEffect(() => {
    var elm = document.getElementById(`${id}-helper-text`);
    const span = document.createElement("span");
    const svgIcon = renderToString(<WarningIcon />);
    span.innerHTML = svgIcon;

    if (elm && !elm.querySelector("span")) {
      elm?.prepend(span);
    }
  });

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case "disablePast": {
        return minDateMessage;
      }
      case "invalidDate": {
        return "Invalid Date Format";
      }
      default: {
        return "";
      }
    }
  }, [error]);

  function handleKeyboardDateTimeChange(date, context) {
    let valueToSet = null;
    if (date && date.isValid()) {
      valueToSet = date.format("MM/DD/YYYY hh:mm a");
    }

    setVal(valueToSet);

    // Only change state in parent component if value is either valid or null
    if (date === null || (valueToSet && date.isValid())) {
      onChange(date);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isMobileDevice ? (
        <ResponsiveDateTimePicker
          format={format}
          disableFuture={disableFuture}
          disablePast={disablePast}
          label={label}
          value={val ? dayjs(val) : val}
          ampm={ampm}
          disabled={disabled}
          onChange={handleKeyboardDateTimeChange}
          onError={(newError) => setError(newError)}
          {...rest}
          slotProps={{
            textField: {
              id,
              className: classes,
              variant: inputVariant,
              placeholder,
              helperText: errorMessage,
            },
          }}
        />
      ) : (
        <DesktopDateTimePicker
          format={format}
          disableFuture={disableFuture}
          disablePast={disablePast}
          label={label}
          value={val ? dayjs(val) : val}
          ampm={ampm}
          disabled={disabled}
          onChange={handleKeyboardDateTimeChange}
          onError={(newError) => setError(newError)}
          {...rest}
          slotProps={{
            textField: {
              id,
              className: classes,
              variant: inputVariant,
              placeholder,
              helperText: errorMessage,
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
}
