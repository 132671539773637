import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useFlexLayout, usePagination } from "react-table";
import classNames from "classnames";
import { sortComparator } from "../../helpers/commonHelpers";
import Pagination from "./Pagination";
import LoadingWrapper from "../LoadingWrapper";
import { debounce } from "../../helpers/objectHelpers";
import styles from "./styles.module.css";
import globalStyles from "../styles.module.css";

const RESIZE_DELAY = 400;

interface Props {
  columns: IHeaderData[];
  data: IRowData[];
  getRowProps?: Function;
  headerHeight?: number;
  rowHeight?: number;
  loading?: boolean;
  loaderType?: "spinner" | "text";
  noDataText?: string;
  className?: string;
  hiddenColumns?: any;
  defaultSortBy?: {
    id: string;
    desc: boolean;
  }[];
  paginationType?: "server" | "default" | "none";
  striped?: boolean;
  gridLines?: Boolean;
  type?: string;
  onTableChange?: Function;
  ignoreHeightAdjust?: boolean;
  fixedHeader?: boolean;
  autoResetSortBy?: boolean;
  pageCount?: number;
  pageIndex?: number;
  tableCaption?: string;
  rowHeaderScope?: boolean;
}

export interface IHeaderData {
  Header: string | JSX.Element;
  width?: number;
  minWidth?: number;
  accessor: string;
  sticky?: boolean;
  className?: string;
  disableSortBy?: boolean;
  sortType?: any;
}

export interface ICellData {
  cellContent: string | JSX.Element | number | Date;
  className?: string;
  sortValue?: any;
  onClick?: Function;
}

export interface IRowData {
  rowContent: {
    [key: string]: ICellData;
  };
  isFixed?: boolean;
  className?: string;
  onClick?: Function;
}

export function ReactTable({
  columns,
  data,
  defaultSortBy = [],
  headerHeight = 0,
  rowHeight = 0,
  getRowProps,
  className = "",
  hiddenColumns,
  paginationType = "none",
  striped = false,
  gridLines = false,
  ignoreHeightAdjust = false,
  loading,
  loaderType = "text",
  noDataText,
  onTableChange,
  type = "primary",
  fixedHeader = true,
  autoResetSortBy = true,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  tableCaption,
  rowHeaderScope,
}: Props) {
  const [scrollingRight, setScrollingRight] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [announcement, setAnnouncement] = useState("");

  const tableRef = React.useRef(null);
  const columnsForTable = React.useMemo(
    () =>
      columns.map((column) =>
        column.disableSortBy || column.sortType
          ? column
          : {
              ...column,
              sortType: (rowA, rowB, columnId, desc) => {
                const sortValueA =
                  data[rowA.index].rowContent[column.accessor]?.sortValue;
                const sortValueB =
                  data[rowB.index].rowContent[column.accessor]?.sortValue;

                return sortComparator(sortValueA, sortValueB, desc);
              },
            }
      ),
    [data]
  );

  const dataForTable = React.useMemo(
    () =>
      data.map((x) =>
        Object.keys(x.rowContent).reduce((contentData, accessor) => {
          contentData[accessor] = x.rowContent[accessor].cellContent;

          return contentData;
        }, {})
      ),
    [data]
  );

  const reactTableData = useTable(
    {
      columns: columnsForTable,
      data: dataForTable,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        sortBy: defaultSortBy,
        pageIndex: controlledPageIndex || 0,
      },
      manualPagination: ["server", "none"].includes(paginationType),
      manualSortBy: false,
      autoResetSortBy,
      pageCount: controlledPageCount,
    },
    useSortBy,
    useFlexLayout,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: { pageIndex, pageSize, sortBy },
    prepareRow,
    setHiddenColumns,
  } = reactTableData;

  useEffect(() => {
    const { debouncedFunction: adjustTableHeightDebounced, timer } = debounce(
      adjustTableHeight,
      RESIZE_DELAY
    );

    if (!ignoreHeightAdjust) {
      adjustTableHeight();
      window.addEventListener("resize", adjustTableHeightDebounced);
    }

    return () => {
      window.removeEventListener("resize", adjustTableHeightDebounced);
      clearTimeout(timer);
    };
  }, []);

  // Listen for changes in pagination/sorting and use the state to fetch our new data
  useEffect(() => {
    if (onTableChange) {
      onTableChange({ pageIndex, pageSize, sortBy });
    }
  }, [pageIndex, pageSize, sortBy]);

  useEffect(() => {
    if (!!hiddenColumns) {
      setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns]);

  function adjustTableHeight() {
    if (tableRef.current) {
      const top = (tableRef.current as any).getBoundingClientRect().top;

      setTableHeight(window.innerHeight - top - 20);
    }
  }

  function getRowAttributes(row, fixedRows) {
    const list: any = [];
    const styleAndClass: any = {
      className: classNames({
        [data[row.index].className || ""]: true,
        [styles.fixedRow]: fixedRows,
      }),
    };

    if (rowHeight > 0) {
      styleAndClass.style = { height: rowHeight };
    }

    list.push(styleAndClass);

    if (getRowProps) {
      list.push(getRowProps(row));
    }

    return row.getRowProps(list);
  }

  function renderRows(rows, fixedRows = false) {
    return rows.map((row) => {
      prepareRow(row);
      const trPropsData = { ...getRowAttributes(row, fixedRows) };
      const { key: trKey, ...trProps } = trPropsData;

      return (
        <tr key={trKey} {...trProps} onClick={data[row.index].onClick}>
          {row.cells.map((cell, idx) => {
            const cellData = data[row.index].rowContent[
              columns[idx].accessor
            ] || { onClick: null };
            const tdPropsData = { ...getCellAttributes(cell, cellData, idx) };
            const { key: tdKey, ...tdProps } = tdPropsData;

            return (
              <td key={tdKey} {...tdProps} onClick={cellData.onClick}>
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      );
    });
  }

  function getHeaderAttributes(column) {
    const list = [column.getSortByToggleProps()];
    const styleAndClass: any = {
      className: classNames(`${column.className}`, {
        [styles.highlight]: column.isSorted,
        [styles.fixedColumn]: column.sticky,
      }),
    };

    if (headerHeight > 0) {
      styleAndClass.style = { minHeight: headerHeight };
    }

    list.push(styleAndClass);

    return column.getHeaderProps(list);
  }

  function getCellAttributes(cell, cellData, idx) {
    const list = [
      {
        className: classNames(
          `cell ${cellData.className || ''} ${cell.column.className || ''}`,
          {
            [styles.fixedColumn]: cell.column.sticky,
            [styles.gridLines]: gridLines,
          }
        ),
        style: cell.column.style,
        role: idx === 0 && rowHeaderScope ? "rowheader" : undefined,
      },
    ];

    return cell.getCellProps(list);
  }

  function getTableAttributes() {
    const list = [
      {
        className: classNames({
          [styles.scrolling]: scrollingRight,
          [styles.primaryTable]: type === "primary",
          [styles.secondaryTable]: type === "secondary",
        }),
        style: getTableStyle(),
      },
    ];

    return getTableProps(list);
  }

  function onTableScroll(ev) {
    if (ev.currentTarget.scrollLeft > 0 && !scrollingRight) {
      setScrollingRight(true);
    } else if (ev.currentTarget.scrollLeft === 0 && scrollingRight) {
      setScrollingRight(false);
    }
  }

  function getTableStyle() {
    return ignoreHeightAdjust || className === "gradebookAssignmentsTable"
      ? {}
      : { maxHeight: tableHeight };
  }

  const fixedRows = page.filter((row, index) => data[index].isFixed);
  const nonFixedRows = page.filter((row, index) => !data[index].isFixed);

  const gradeHeaderTabHandler = (e) => {
    if (e.key === "Enter") {
      e.target.click();
    }
  };

  const sortHandler = (column) => {
    const headerName =
      column.id !== "questionProgress"
        ? column.title
          ? column.title
          : column.Header
        : column.Header?.props?.children?.[0] ?? "";
    const sortingType = column.isSorted
      ? !column.isSortedDesc
        ? "sorted by descending"
        : "sorted by ascending"
      : "no sorting";
    setAnnouncement(`${headerName} ${sortingType}`);
  };

  // Render the UI for your table
  return (
    <LoadingWrapper
      isLoading={loading}
      hasNoData={page.length === 0}
      noDataText={noDataText}
      type={loaderType}
    >
      <>
        <div
          className={`reactTable ${styles.tableWrapper} ${className}`}
          onScroll={onTableScroll}
          style={getTableStyle()}
          tabIndex={-1}
        >
          <div
            className={globalStyles.visuallyHidden}
            style={{ display: announcement !== "" ? "block" : "none" }}
            aria-live="assertive"
            aria-atomic="true"
          >
            {announcement}
          </div>
          <table ref={tableRef} {...getTableAttributes()}>
            {tableCaption && (
              <caption className={globalStyles.visuallyHidden}>
                {tableCaption}
              </caption>
            )}
            <thead
              className={classNames({ [styles.fixedHeader]: fixedHeader })}
            >
              {headerGroups.map((headerGroup) => {
                const trPropsData = { ...headerGroup.getHeaderGroupProps() };
                const { key: trKey, ...trProps } = trPropsData;
                return (
                  <tr key={trKey} {...trProps}>
                    {headerGroup.headers.map((column) => {
                      const headerAttributes = getHeaderAttributes(column);
                      const { key: thKey, ...thProps } = headerAttributes;
                      return (
                        <th
                          key={thKey}
                          {...thProps}
                          {...(column.isSorted && {
                            "aria-sort": column.isSortedDesc
                              ? "descending"
                              : "ascending",
                          })}
                        >
                          <div
                            onClick={() => sortHandler(column)}
                            onKeyDown={(e) => gradeHeaderTabHandler(e)}
                            role={
                              !column.interactiveBtn &&
                              typeof column.Header?.props?.title === 'undefined'
                                ? 'button'
                                : undefined
                            }
                            tabIndex={
                              !column.interactiveBtn &&
                              typeof column.Header?.props?.title === 'undefined'
                                ? 0
                                : undefined
                            }
                            className={
                              column.interactiveBtn && styles.columnHeader
                            }
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              <span
                                className={classNames(styles.sortArrow, {
                                  [styles.arrowDown]: column.isSortedDesc,
                                  [styles.arrowUp]: !column.isSortedDesc,
                                })}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
              {fixedRows.length > 0 && renderRows(fixedRows, true)}
            </thead>
            <tbody
              className={classNames({ [styles.striped]: striped })}
              {...getTableBodyProps()}
            >
              {renderRows(nonFixedRows)}
            </tbody>
          </table>
        </div>
        {paginationType !== "none" && <Pagination {...reactTableData} />}
      </>
    </LoadingWrapper>
  );
}
