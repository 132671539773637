import React, { useEffect } from 'react';
import { Dropdown, IDropdownItem } from '../inputs';
import { openNewWindow } from '../../helpers/commonHelpers';
import { helpArticleLink } from '../../helpers/constants';
import HelpIcon from '../../icons/help.svg';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '../tooltip';
import ExternalLink from '../ExternalLink';

interface Props {
  isRoleStudent: boolean;
  isAnonUser: boolean;
  chatWithUs: Function;
  readerAppUrl: string;
  setCookie: Function;
  isMath: boolean;
  iconWidth: number;
  iconHeight: number;
  className?: string;
  isStudentPreview?: boolean;
  [key: string]: any;
}

const useStyles = makeStyles({
  helpIntroVideo: {
    fontSize: '14px',
    marginLeft: '10px',
    color: '#0077D1 !important',
    cursor: 'pointer',
    textDecoration: 'none',
  },
});

export default function HelpMenu(props: Props): JSX.Element {
  const { iconWidth, iconHeight, className = "" } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  
  useEffect(() => {
    const handleKeydown = (event: any) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  useEffect((): any => {
    if (open) {
      const elm = window.document.querySelector(
        ".intercom-messenger-frame"
      ) as HTMLElement;
      elm?.removeAttribute("tabindex");
    }
  });

  return (
    <Dropdown
      id="headerHelpMenu"
      buttonClass={className}
      variant="iconSecondary"
      keepMounted={false}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      aria-label="Help & Support"
      align="right"
      buttonContent={
        <Tooltip 
          id="btnHeaderHelpMenu"
          title="Help & Support"
          role="button"
          open={open}>
          <HelpIcon width={iconWidth} height={iconHeight} role="presentation" />
        </Tooltip>
      }
      dropdownItems={getHelpMenuDropdownItems(props)}
    />
  );
}

export function getHelpMenuDropdownItems(props): IDropdownItem[] {
  const styles = useStyles({});
  const { isRoleStudent, isAnonUser, chatWithUs, readerAppUrl, setCookie, isMath = false, isStudentPreview = false } = props;
  const isStudentPreviewOrRoleStudent = isStudentPreview || isRoleStudent;

  function goToHelpCenter(): void {
    if (isMath) {
      openNewWindow('https://achievemath.zendesk.com/hc/en-us', '_blank');
    }
    else {
      const url = isStudentPreviewOrRoleStudent ? 'https://helpstudent.activelylearn.com' : 'http://help.activelylearn.com';
      openNewWindow(url, '_blank');
    }
  }

  const items: IDropdownItem[] = [
    {
      content: 'Help Center',
      onClick: goToHelpCenter,
      cssClass: 'helpMenuDropdownHelpCenter',
      ariaLabel: 'Help Center (Opens in new tab)',
    },
  ];

  if (isStudentPreviewOrRoleStudent) {
    if (!(isAnonUser || isMath)) {
      items.push({
        content: 'Getting Started video',
        onClick: () => openNewWindow(helpArticleLink.studentOverview, '_blank'),
        cssClass: 'helpMenuDropdownGettingStartedVideo',
        ariaLabel: 'Getting Started video (Opens in new tab)', 
      });
    }
  }
  else {
    if (!isMath) {
      items.push({
        content: (
          <>
            Overview Videos:{' '}
            <ExternalLink className={styles.helpIntroVideo} href={helpArticleLink.studentOverview}>
              Student
            </ExternalLink>
          </>
        ),
        cssClass: 'helpMenuDropdownOverviewVideos',
      });
    }

    if (!isAnonUser) {
      items.push({
        content: 'Send us a message',
        onClick: () => chatWithUs(),
        cssClass: 'helpMenuDropdownSendUsAMessage',
      });
    }

    if (!isMath) {
      items.push({
        content: 'New product features',
        onClick: () => openNewWindow(helpArticleLink.whatsNew, '_blank'),
        cssClass: 'helpMenuDropdownNewProductFeatures',
      });
    }
  }

  return items;
}
